<template>

  <div style="height: 32px;line-height: 32px;padding: 0.13rem 0.56rem;position: relative">
    <div style="width: 32px;margin-right: 6px;position: absolute;left: 0.56rem" @click="addTestData">
      <img src="@/assets/img/county/alarm.png" style="width: 32px;height: 32px;display: block"/>
    </div>
    <div style="position:absolute;left: 0.94rem;right:0.56rem;height: 32px">
      <div style="position:relative;color:#FE0000;font-size:0.16rem;font-weight: bold;overflow: hidden;">
        <span>报警数据：</span>
        <div style="position: absolute;left: 80px;right: 0;top: 0;bottom: 0;overflow: hidden" ref="wrapper"
             @mouseenter="bCanMove = false" @mouseleave="bCanMove =  true">
          <div style="white-space: nowrap;" ref="content" :style="{transform: 'translateX('+current+'px)'}">
            <div ref="item" style="display: inline-block;padding-right: 30px;" v-for="(item, index) in scrollingList"
                 :key="index" @click="onItemClick(index)">
              {{ item.warnMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { socketUrl } from '@/config/config'

export default {

  computed: {
    userInfo () {
      return this.$store.state.user.userInfo
    }
  },

  data () {
    return {
      current: 0,
      wrapper: 0,
      bCanMove: true,
      waitScrollPool: [],
      scrollingList: [],
      lastNodeWidth: 0,
      clickedIndexList: [],
      speed: 2,
      bScrolling: false,
      timer: null,
      bLastNodeClicked: false,
      prevHour: 0
    }
  },

  mounted () {
    window.requestAnimFrame = (function () {
      return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function (/* function */ callback, /* DOMElement */ element) {
          window.setTimeout(callback, 1000 / 60)
        }
    })()

    this.connect()
    const wrapper = this.$refs.wrapper
    this.current = this.wrapper = wrapper.offsetWidth
    this.startMarquee()
    this.timer = setInterval(() => {
      const hours = (new Date()).getHours()
      if (this.prevHour === 23 && hours === 0) { // 新的一天
        if (!this.bScrolling) this.scrollingList = []
      }
      this.prevHour = hours
    }, 60000 * 5)
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },

  methods: {
    startMarquee () {
      // 将等待滚动列表放入滚动中
      this.scrollingList = this.scrollingList.concat(this.waitScrollPool)
      // 去除被点击的
      this.clickedIndexList.sort().reverse()
      this.clickedIndexList.forEach(index => this.scrollingList.splice(index, 1))
      this.clickedIndexList = []
      this.waitScrollPool = []
      const scrollingList = []
      this.scrollingList.forEach((item) => {
        if (item.viewNum-- > 0) scrollingList.push(item)
      })
      if (!scrollingList.length && this.scrollingList.length) {
        scrollingList.push(this.scrollingList[this.scrollingList.length - 1])
      }
      this.scrollingList = scrollingList
      //
      // for (let i = this.scrollingList.length - 1; i >= 0; i--) {
      //   const item = this.scrollingList[i]
      //   if (item.viewNum === 0) {
      //     if (this.scrollingList.length > 1) {
      //       this.scrollingList.splice(i, 1)
      //     }
      //   } else {
      //     item.viewNum--
      //   }
      // }
      setTimeout(() => {
        if (!this.scrollingList.length) return (this.current = this.wrapper)
        let width = 0
        this.$refs.item.forEach(item => {
          width += item.offsetWidth
          this.lastNodeWidth = item.offsetWidth
        })
        this.current = this.wrapper
        this.move(width)
      }, 30)
    },

    move (width) {
      if (this.bCanMove) {
        this.current -= this.speed
        // 只剩下一个时，并且没有被点击过，滚动结束后停留
        if (this.scrollingList.length === 1 && !this.waitScrollPool.length) {
          if (this.current < 0 && this.scrollingList[this.scrollingList.length - 1].viewNum < 1) {
            this.bScrolling = false
            console.log(this.clickedIndexList, "this.clickedIndexList")
            if (this.clickedIndexList.length) this.startMarquee()
            return
          }
        }
        if (this.current < 0 && Math.abs(this.current) > width) {
          return this.startMarquee()
        }
      }
      window.requestAnimFrame(() => {
        this.bScrolling = true
        this.move(width)
      })
    },

    // 连接服务器
    connect () {
      const ws = new WebSocket(socketUrl + '/district-command-center?eduId=' + this.userInfo.organizationIdStr)
      ws.onopen = () => {
        console.log('WebSocket opened')
      }

      ws.onclose = () => {
        console.log('WebSocket closed')
        this.connect()
      }
      ws.onerror = function (error) {
        console.log('WebSocket error', error)
      }

      ws.onmessage = this.messageHandle
    },

    messageHandle (e) {
      console.log(e)
      const data = JSON.parse(e.data)
      this.waitScrollPool.push(data)
      if (!this.bScrolling) this.startMarquee()
    },

    addTestData () {
      this.messageHandle({
        data: JSON.stringify({
          warnMessage: '【滚动报警测试】' + (Math.round(Math.random() * 999)),
          viewNum: Math.round(Math.random() * 4) || 1,
          templateCode: ['temperatureData', 'alarmInfoData'][Math.round(Math.random())]
        })
      })
      // this.messageHandle({
      //   data: JSON.stringify({
      //     warnMessage: '【在校预警】' + (Math.round(Math.random() * 999)),
      //     viewNum: 2,
      //     templateCode: ['temperatureData', 'alarmInfoData'][Math.round(Math.random())]
      //   })
      // })
      // setTimeout(() => {
      //   this.messageHandle({
      //     data: JSON.stringify({
      //       warnMessage: '【在校预警】' + (Math.round(Math.random() * 999)),
      //       viewNum: 1,
      //       templateCode: ['temperatureData', 'alarmInfoData'][Math.round(Math.random())]
      //     })
      //   })
      // }, 120)
      //
      // setTimeout(() => {
      //   this.messageHandle({
      //     data: JSON.stringify({
      //       warnMessage: '【在校预警】' + (Math.round(Math.random() * 999)),
      //       viewNum: 2,
      //       templateCode: ['temperatureData', 'alarmInfoData'][Math.round(Math.random())]
      //     })
      //   })
      // }, 220)
    },

    onItemClick (index) {
      this.clickedIndexList.push(index)
      const item = this.scrollingList[index]
      item.viewNum = 0
      this.$emit('click', JSON.parse(JSON.stringify(item)))
      if (this.scrollingList.length === 1 && !this.bScrolling) {
        this.scrollingList = []
        this.bCanMove = true // 点击导致鼠标事件失效
        this.startMarquee()
      }
    }
  }
}
</script>
<style scoped>

</style>
